import React from 'react';
import '../styles/information.css';
const Information = () => {
    return (
        <div className='information-container'>
            <h3>
                Welcome! This website is designed to help master basic arithmetic problems.
            </h3>
        </div>
    );
}


export default Information;